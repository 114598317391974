/* VerifyOtp.css */
.otp-content {
    position: relative;
}

.otp-inputs {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.otp-input {
    width: 50px;
    height: 50px;
    font-size: 24px;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #ced4da;
    margin: 0 5px;
    transition: border-color 0.3s ease-in-out;
}

.otp-input:focus {
    border-color: #80bdff;
    outline: none;
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.25);
}

.otp-input::placeholder {
    color: #6c757d;
    opacity: 1;
}

.otp-inputs .otp-input:nth-child(1) {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.otp-inputs .otp-input:nth-child(6) {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}
