.filterBar {
    display: flex;
    align-items: center;
    background-color: white;
    padding: 1rem;
    border-radius: 0.25rem;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
    border: 1px solid #EAECF0;
    width: 95%;
  }
  
  .inputGroup {
    margin-right: 0.5rem;
    display: block;
  }
  
  .buttonPrimary {
    background-color: #007bff;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  
  .buttonPrimary:hover {
    background-color: #0056b3;
  }
  

  .formLabel{
    color: #344054;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .formUi{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;

  }

  .formContainer{
    
    display: flex;
    /* justify-content: space-between; */
    align-items: center;
    width: 100%;
  }

  .formInputs{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .SubmitBtn{
    width: 20%;

    display: flex;
    justify-content: end;
    align-items: center;
    /* margin-top: 50%; */
  }
  .fontIcons{
    margin-right: 5px;
  }

  .singleForm{
    max-width: 15em;
    /* background-color: red; */
  }