.sidebar {
    display: block;
    position: fixed;
    width: 100%;
    max-width: var(--sidebar-width);
    top: 0;
    bottom: 0;
    transition: var(--sidebar-transition);
    transition-duration: var(--sidebar-transition-duration);
    transition-timing-function: var(--sidebar-transition-function-ease);
    z-index: 910;
    background-color: $white;
    color: var(--#{$variable-prefix}primary);
    box-shadow: 0 0 30px rgba(var(--#{$variable-prefix}primary-rgb), .05);

    .sidebar-body {
        padding-right: 1rem;
        overflow: hidden;
    }

    .data-scrollbar {
        max-height: 92vh;
    }

    .sidebar-header {
        border-bottom: $border-width solid tint-color($border-color, 20%);
        margin-bottom: $spacer * .5;
    }

    .sidebar-logo {
        transition-property: transform, opacity;
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease);
        margin-left: 1rem;
        margin-bottom: 0;
    }

    .navbar-brand {
        display: flex;
        align-items: center;
        padding: $spacer;

        svg {
            color: var(--#{$variable-prefix}primary);
        }

        .logo-title {
            margin-left: $spacer;
            margin-bottom: 0;
            transition: var(--sidebar-transition);
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(--sidebar-transition-function-ease);
            transform: translateX(0%);
            opacity: 1;
            color: $dark;
        }
    }

    .logo-main {
        .logo-mini {
            display: none;
        }

        .logo-normal {
            display: block;
        }
    }

    .sidebar-toggle {
        position: absolute;
        right: -12px;
        top: 16px;
        cursor: pointer;
        background: var(--#{$variable-prefix}primary);
        color: $white;
        padding: 0.2rem;
        border-radius: $border-radius-pill;
        box-shadow: $component-active-shadow;

        &:hover {
            box-shadow: $component-hover-shadow;
        }

        .icon {
            display: flex;
            transform: rotate(0);
            transition: var(--sidebar-transition);
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(--sidebar-transition-function-ease);
        }
    }
}

.sidebar-base {
    .nav-item {
        position: relative;
        transition-property: background-color, color, border-radius, transform, opacity, padding-left, padding-right;
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease);
        margin-top: 2px;
        color: var(--#{$variable-prefix}gray-600);

        &:not(.static-item) {
            padding-left: 1rem;
        }

        &.static-item {
            .default-icon {
                text-transform: uppercase;
                font-size: calc(1rem - 2px);
                letter-spacing: .18rem;
                color: var(--#{$variable-prefix}gray-900);
            }
        }

        .disabled {
            color: var(--#{$variable-prefix}gray-400);
            cursor: unset !important;
        }

        .nav-link {
            // font-weight:600;
            transition-property: background-color, color, border-radius, transform, opacity;
            transition-duration: var(--sidebar-transition-duration);
            transition-timing-function: var(--sidebar-transition-function-ease);
            cursor: pointer;
            line-height: 1.5;
            color: inherit;

            &.active {
                transition-property: background-color, color, border-radius, transform, opacity;
                transition-duration: var(--sidebar-transition-duration);
                transition-timing-function: var(--sidebar-transition-function-ease);
                color: var(--#{$variable-prefix}primary);
            }

            &:hover {
                color: var(--#{$variable-prefix}primary);
            }

            &.static-item {
                margin-bottom: 0.5rem;

                .mini-icon {
                    display: none;
                }
            }

            .item-name {
                flex: 1;
                margin-left: 1rem;
                transform: translateX(0);
                opacity: 1;
            }

            .sidenav-mini-icon {
                opacity: 0;
                display: none;
            }

            text-transform: capitalize;

            &:not(.disabled) {
                .right-icon {
                    transition: var(--sidebar-transition);
                    transition-duration: var(--sidebar-transition-duration);
                    transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                    display: flex;
                }

                .icon {
                    display: flex;
                }

                color: $gray-900;

                &.active,
                &[aria-expanded=true] {
                    .right-icon {
                        transition: var(--sidebar-transition);
                        transition-duration: var(--sidebar-transition-duration);
                        transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                        transform: rotate(90deg);
                    }
                }

                &.active {
                    &[aria-expanded=false] {
                        .right-icon {
                            transition: var(--sidebar-transition);
                            transition-duration: var(--sidebar-transition-duration);
                            transition-timing-function: var(--sidebar-transition-function-ease-in-out);
                            transform: rotate(0deg);
                        }
                    }
                }
            }

            padding: 0.75rem 1rem;
            display: flex;
            align-items: center;
            white-space: nowrap;
            text-transform: capitalize;
        }
    }

    .sub-nav {
        padding: 0;
        margin: 0;
        overflow: hidden;
        transition: var(--sidebar-transition);
        transition-duration: var(--sidebar-transition-duration);
        transition-timing-function: var(--sidebar-transition-function-ease-in-out);
    }
}

body {
    --sidebar-width: #{$navbar-vertical-width};
    --sidebar-transition: #{$navbar-vertical-transition};
    --sidebar-transition-duration: #{$navbar-vertical-transition-duration};
    --sidebar-transition-function-ease: #{$navbar-vertical-transition-function-ease};
    --sidebar-transition-function-ease-in-out: #{$navbar-vertical-transition-function-ease-in-out};
}