.pending-email-container-wrapper{
  height: 88vh;
  background-color: #F9FAFB;
  padding: 2% 2%;
}

.pending-email-container {
    display: flex;
    align-items: flex-start;
    width: 100%;
    flex-direction: row;
    background-color: #F9FAFB;
    height: 90%;
    /* overflow: auto; */
  }

  .email-subject{
    font-size: 16px;
    font-weight: 500;
    color: #101828;
  }
  
  .email-content-wrapper {
    flex: 1;
    padding: 10px;
    width: 55%;
    /* border-right: 1px solid #EAECF0; */
    border: 1px solid #007AFF;
    border-radius: 8px;
    padding: 1.5%;
    margin-left: 1.5%;
    height: 100%;
    background-color: #fff;
  }
  
  .email-header {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    margin-top: 10px;
    border-bottom: 1px solid #EAECF0;
  }
  .to-user{
    font-weight: 500;
    font-size: 1.1rem;
    color: #101828;
    margin-right: 5PX;
  }

  .employement-breakdown{
    margin-top: 5px;
    margin-bottom: 5px;
  }
  
  .person-image {
    width: 40px; /* Adjust size as needed */
    height: 40px; /* Adjust size as needed */
    border-radius: 50%;
    margin-bottom: 10px;
  }

  .person-image-posts{
    width: 32px; /* Adjust size as needed */
    height: 32px; /* Adjust size as needed */
    border-radius: 50%;
    margin-right: 8px;

  }
  .post-list{
    list-style-type: none; /* Removes bullet points */
    padding: 0; /* Removes padding */
    margin: 0; /* Removes margin */
  }

  .posts-container{
    margin-left: 40px;
    border: 1px solid #EAECF0;
    padding: 2%;
    margin-top: 10px;
    font-size: 0.8rem;
  }

  .person-name{
    font-size: 0.8rem;
    color: #475467;
    margin-bottom: 10px;
    margin-left: 5px;
  }

  .person-name-posts{
    font-size: 0.8rem;
    font-weight: 500;
    color: #344054;
  }

  .user-email-info{
    display: flex;
    align-items: center;
  }

  .employment-breakdown-title{
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 0;
  }

  .employment-breakdown-timeline{
    font-size: 0.8rem;
    font-weight: 400;
    margin-bottom: 0;
  }
  
  .email-content {
    width: 100%;
    border: 0px solid #ccc;
    padding: 10px 0;
    box-sizing: border-box;
    font-weight: 400;
    line-height: 1.4;
    font-size: 1.0rem;
    max-height: 60%;
    overflow: auto;
  }
  


  .post-item{
    margin-bottom: 10px;
  }

  .recent-news-container{
    display: flex;

  }
  .recent-news-logo{
    width: 40px;
    height: 40px;
    margin-right: 10px;
  
    
  }
  
  .read-only {
    /* background-color: #f9f9f9; */
    cursor: not-allowed;
  }
  
  .all-btn-groups {
    display: flex;
    justify-content:center;
    /* align-items: center; */
    margin-top: 10px;
    /* width: 100%; */
  }
 /* .vertical-line {
    position: absolute;
    left: 50%;
    height: 100%;
    border-left: 1px solid #EAECF0;
    transform: translateX(-50%);
} */
.right-container{
    width: 45%;
    margin-left: 2%;
    /* max-height: 100%; */
    /* background-color: red; */
    /* height: 100vh; */
    /* border-left: 1px solid #EAECF0; */
}


/* Pagination Controls */
.pagination-controls {
    margin-top: 20px;
    display: flex;
    justify-content: center;
    gap: 15px;
}

/* Subtle Previous and Next Buttons */
.pagination-controls button {
    padding: 8px 16px;
    font-size: 0.8rem;
    border-radius: 20px;
    background-color: #6c757d;
    color: #fff;
    border: none;
    transition: background-color 0.3s ease;
}

.pagination-controls button:hover {
    background-color: #5a6268;
}

.pagination-controls button:disabled {
    background-color: #c6c6c6;
    cursor: not-allowed;
}

.info-header{
    display: flex;
    justify-content: space-between;
  }

.info-header-left{
    font-weight: 600;
    font-size: 1rem;
    line-height: 28px;
}

.info-header-right{
    font-weight: 600;
    font-size: 0.8rem;
    line-height: 20px;
    border: 1px solid #D0D5DD;
    padding: 1%;
    border-radius: 5px;
    cursor: pointer;
    background-color: white;

}

  
  /* button styling start */
  
  
  
  .btn {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    /* border: 1px solid red; */
    border-radius: 0px;
    /* padding: 2%; */
    font-size: 0.9rem;
  }
  .btn-approve{
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    border: 1px solid #D0D5DD;
  }
  .btn-refetch{
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
  }
  .btn-edit{
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    border-right: 1px solid #D0D5DD;
    /* border-left: 1px solid #D0D5DD; */
    /* border-top-left-radius: 5px; */
    /* border-bottom-left-radius: 5px; */

  }
  .btn-save{
    border-top: 1px solid #D0D5DD;
    border-bottom: 1px solid #D0D5DD;
    border-right: 1px solid #D0D5DD;
    border-left: 1px solid #D0D5DD;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;

  }
  .btn-delete{
    border: 1px solid #D0D5DD;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
    /* border-radius: 5px; */
  }

  /* .btn-left-group{
    display: flex;
  } */

  .comp-info-container{
    /* background-color: ; */
    padding-bottom: 2%;
  }

  .comp-name-logo-location-container{
    display: flex;
    flex-direction: column;
    /* padding: 10px; */
    width: 100%;
    margin-top: 2%;
    background-color: #fff;
  }

  .comp-name-white-bg{
    width: 100%;
    background-color: white;
    padding: 3%;
    border-radius: 10px;
    display: flex;
  }

  .comp-info-name-white-bg{
    width: 100%;
    background-color: white;
    padding: 3%;
    border-radius: 10px;
    display: flex;
  }
  
  .company-image{
    width: 96px; /* Adjust size as needed */
    height: 96px; /* Adjust size as needed */
    border-radius: 10px;
    margin-bottom: 10px;
    /* border : 1px solid grey; */

  }
  .comp-name-location{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 20px;
    margin-bottom: 10px;
  }
  .comp-name{
    font-weight: 600;
    font-size: 1.4rem;
  }
  .comp-location{
    font-weight: 600;
    font-size: 0.9rem;
  }

  .comp-industry{
    font-size: 0.9rem;
    color: #475467;
    margin-top: 5px;
    margin-bottom: 5px;

  }
  .comp-details-container{
    font-size: 0.7rem;
    display: flex;
    margin-left:20px;
    margin-bottom : 10px;
  }
  .company-icon{
    font-size: 16px;
    width: 18px;
  }
  .comp-details-icon{
    margin-right: 5px;
  }
  .comp-details-span{
    margin-right: 15px;
  }

  .tabs-container {
    margin: 20px;
    font-size: 0.8rem;
  }
  
  .tabs {
    display: flex;
    border-bottom: 1px solid #e0e0e0;
    
  }
  
  .tab {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    background: none;
    outline: none;
    font-size: 14px;
    color: #667085;
    font-weight: 600;
  }
  
  .about-header{
    font-size: 16px;
    font-weight: 600;
    color: #475467;
  }


  .tab.active {
    border-bottom: 2px solid #007AFF;
    color: #007AFF;
    font-weight: bold;
  }
  
  .tab-content {
    margin-top: 20px;
  }
  
  .badge {
    background-color: #f0f0f0;
    color: #000;
    padding: 3px 7px;
    border-radius: 50%;
    font-size: 12px;
    margin-left: 5px;
  }
  

  .scrollable-about {
    font-size: 14px;
    max-height: 31vh; /* Adjust the height as needed */
    overflow-y: auto; /* Adds a scrollbar if content exceeds the max-height */
    margin-top: 15px; /* Optional: adds space above the scrollable area */
    color: #475467;
    border-radius: 4px; /* Optional: rounds the corners of the border */
    background-color: #f9f9f9; /* Optional: adds a background color */
  }
  
  .scrollable-about::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
  }
  
  .scrollable-about::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar thumb */
    border-radius: 4px; /* Rounds the corners of the scrollbar thumb */
  }
  
  .scrollable-about::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar thumb on hover */
  }
  
  .scrollable-about .read-more {
    /* display: block; */
    margin-top: 10px; /* Adds space between the content and the link */
    color: #007bff; /* Color for the "Read more" link */
    text-decoration: underline; /* Underlines the link */
    cursor: pointer; /* Changes the cursor to a pointer */
  }
  
  .scrollable-about .read-more:hover {
    color: #0056b3; /* Color for the "Read more" link on hover */
  }

  .comp-name-user-container{
    margin-right: 2px;
    margin-left: 0.2rem;
  }
  .pill-box {
    padding: 8px 16px;
    border-radius: 20px;
    background-color: #f0f4ff;
    color: #3538CD;
    font-size: 0.8rem;
    border: 2px solid #e0e0e0;
    display: inline-block;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin-bottom: 5px;
    margin-right: 5px;
  }
  
  .pill-box:hover {
    background-color: #e0e4ff;
    color: #303f9f;
  }
/* 
  .comp-logo{
    padding: 2%;
  } */

  .comp-logo{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .generate-email-banner{
    border-bottom: 1px solid #EAECF0;
    padding-bottom: 1%;
    font-size: 1.1rem;
    font-weight: 600;

  }

  .email-content-upper{
    height: 90%;

  }

  /* .experience-container{
    display: flex;
    justify-content: center;
    align-items: center;

  } */

  .experience-container-name{
    display: flex;
  }

  .experience-company-logo{
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin-right: 10px;
  }


  .experience-list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 0.9rem;
  }
  
  .experience-item {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border-bottom: 1px solid #EAECF0;
  }
  
  .experience-content {
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 0.9rem;
  }
  
  .experience-logo img {
    width: 50px;
    height: 50px;
    object-fit: cover;
    margin-right: 15px;
    border-radius: 5px;
  }
  
  .experience-logo .no-logo {
    width: 50px;
    height: 50px;
    background-color: #ccc;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    border-radius: 5px;
    font-size: 14px;
    color: #666;
  }
  
  .experience-details {
    display: flex;
    flex-direction: column;
    font-size: 0.9rem;
    margin-left: 50px;
  }

  .company-name{
    margin: 5px 0;
  }
  
  .company-name a {
    font-size: 14px;
    color: #007aff;
    text-decoration: none;
    font-weight: 400;
    
  }
  
  .company-name a:hover {
    text-decoration: underline;
    
  }

  .employment-title {
    color: #475467;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px;
  }
  
  .employment-duration {
    color: #475467;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 5px;
    margin-left: 20px;
  }

  .employment-duration-company::before {
    content: "• "; /* Unicode character for a dot */
    font-size: 16px; /* You can adjust the size of the dot */
  }
  .employment-description {
    color: #555;
    font-size: 0.7rem;
    margin-bottom: 2px;
  }

  .company-name-breakdown{
    font-size: 0.8rem;
    display: flex;
    flex-direction: column;
  }
  /* rewrite popup starts here */
  

  /* rewrite popup ends here */