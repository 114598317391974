/*!
* Version: 1.2.0
* Template: Example Project
* Author: iqonic.design
* Design and Developed by: iqonic.design
* NOTE: This file contains the styling for Template.
*
*/

// Configuration
@import "./bootstrap/functions";
// Variables
@import "./hope-ui-design-system/variable";
@import "./hope-ui-design-system/variables/index";
@import "./bootstrap/variables";

@import "./bootstrap/mixins";

// Hope Ui Design System Mixin And Helper
@import "./hope-ui-design-system/helper/functions";
@import "./hope-ui-design-system/helper/mixins";
@import "./hope-ui-design-system/helper/reboot";

@import "./custom/auth/authentication";
@import "./custom/kanban/kanban";
@import "./custom/pricing/pricing";
@import "./custom/ui-kit/ui-kit";

.sidebar-base .nav-item .nav-link .item-name{
    font-size: 16px;
}
aside{
    background-color: #FAFCFF;
    border-right:1px solid #C9C9C9;
}
.iq-navbar{
    background-color: #FFFFFF;
    border-bottom:1px solid #C9C9C9;
}
.content-inner{
    background-color: #FFFFFF;
}
.form-control{
    box-shadow: 0 4px 10.6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    padding:10px;
    padding-left:15px;
    padding-right:15px;
    border:1px solid #C9C9C9;
}
.form-label{
    font-weight:500;
    color:#121212;
}
.css-b62m3t-container{
    box-shadow: 0 4px 10.6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 10px !important;
}
.nav-pills .nav-link{
    border:1px solid #EEEEEE;
    box-shadow: 0 4px 10.6px 0 rgba(0, 0, 0, 0.05);
}
.table thead tr{
    background-color: #E8F0FD;
}
.table tbody tr td{
    color: #74828F;
}

.rti--tag{
    background: #FFF !important;
    border: 1px solid #D0D5DD;
    font-size:85% !important;
}
.expandable-cell {
    min-width: 350px; /* Example: Limiting the width of the cell */
    max-width: 350px;
    max-height: 50px;
    white-space: wrap; /* Preserve whitespace and wrap text */
    overflow: hidden; /* Hide overflowing text */
    cursor: pointer; /* Show pointer cursor on hover */
    padding: 8px; /* Example: Adjust padding as needed */
}

.table-cell {
    max-width: 350px;
    max-height: 50px;
    white-space: wrap; /* Preserve whitespace and wrap text */
    overflow: hidden; /* Hide overflowing text */
    padding: 8px; /* Example: Adjust padding as needed */
}

.expanded {
    max-height: 200px; /* Example: Adjust maximum height when expanded */
    min-width: 350px; /* Maintain minimum width */
    white-space: pre-wrap; /* Preserve whitespace and wrap text */
    overflow: auto; /* Allow scrolling if necessary */
    cursor: initial; /* Reset cursor to default */
}