/* Header.module.css */

.headerContainer {
    /* background: linear-gradient(90deg, #2d2e83 0%, #5a5fcf 100%); */
    color: black;
    padding-top: 20px;
    padding-left: 10px;
    /* border-radius: 8px; */
    border-bottom: 1px solid #EAECF0 ;
    height: 12vh;
  }

  .headerContainerPendingEmail {
    /* background: linear-gradient(90deg, #2d2e83 0%, #5a5fcf 100%); */
    color: black;
    padding-top: 20px;
    padding-left: 10px;
    /* border-radius: 8px; */
    border-bottom: 1px solid #EAECF0 ;
    height: 12vh;
    background-color: #F9FAFB;
  }
  

  .findLeadsContainer{
    display: flex;
    justify-content: space-between;
  }

  .headerTitle {
   height: 38px;  align-self: stretch;  flex-grow: 0;  font-size: 20px;  font-weight: 600;  font-stretch: normal;  
   font-style: normal;  line-height: 1.9;  letter-spacing: normal;  text-align: left; 
  }
  
  .headerSubtitle {
    height: 24px;
  align-self: stretch;
  flex-grow: 0;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 24px;
  letter-spacing: normal;
  text-align: left;
  /* color: #ebedf0; */
  color: #344054;
  }
  
  .addLeadsBtn {
    background-color: #007aff;
    border-color: #2578d2;
    font-size: 0.9rem;
  }
  
.leadsUploadBtn{
    /* background-color: #F9FAFB !important; */
    border-color: #D0D5DD !important;
    color: #344054 !important;
    font-size: 0.9rem;
    margin-right: 5px;
    cursor: pointer !important;
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
    border: 1px solid #D0D5DD;
    padding: 3%;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 7rem;
    height: 60%;

  }
  .leadsFindLeadsBtn{
    /* background-color: #F9FAFB !important; */
    border-color: #D0D5DD !important;
    color: #344054 !important;
    padding: 3%;
    font-size: 0.9rem;
    /* margin-right: 5px; */
    cursor: pointer !important;
    border-right: none;
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
    border: 1px solid #D0D5DD;
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 8rem;
    height: 60%;
  }


  