.carouselContainer {
    position: relative;
    width: 100%;
    height: 100%;
}

.carouselItem {
    position: relative;
}

.image {
    width: 100%;
    height: auto;
}

.carouselContent {
    position: absolute;
    bottom: 20px;
    left: 20px;
    color: white;
    background: rgba(0, 0, 0, 0.5);
    padding: 15px;
    border-radius: 8px;
    max-width: 80%;
    z-index: 2;
}

.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}

.subtitle {
    font-size: 1rem;
    margin: 0;
}

.carouselPrev, .carouselNext {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.7);
    color: white;
    border: none;
    font-size: 24px;
    padding: 10px;
    z-index: 10;
    cursor: pointer;
}

.carouselPrev {
    left: 10px;
}

.carouselNext {
    right: 10px;
}
