.campaign-container{
    background-color: #F9FAFB;
}


.leads-column {
    /* background-color: #ffffff; */
}

.chat-column {
    /* background-color: #ffffff; */
    display: flex;
    flex-direction: column;
    /* border-left: 1px solid #ddd; */
}

.message-reply-btn{
    border-radius: 8px;
    background-color: #F9FAFB;
    font-size: 14px;
    font-weight: 600;
}
.messages-container {
    flex: 1;
    overflow-y: auto;
    padding: 10px;
}

.single-person-image{
    display: flex;
    cursor: pointer;
    border-bottom: 1px solid #EAECF0;
    align-items: center;
    padding: 2% 0%;
    margin-left: 5%;
    margin-right: 5%;
}

.campaign-inbox-container{
    display: flex;
    height: 88vh;
}


.user-message-box-container{

    width: 100%;
    /* background-color: red; */

}

.email-preview-container{
    font-size: 14px;
    padding: 1% 2%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.email-preview-container div:first-child {
    padding-bottom: 0 !important;
}

.email-body-container{
    font-size: 14px;
    padding: 1% 0%;
}

.mail-box-subject{
    font-size: 14px;
    font-weight: 600;
    color: #101828;
    padding-left: 1%;
    margin-top: 1%;
}

.card-header{
    padding: 1%;
}

.user-message-details{
    display: flex;
    padding-bottom: 10px;

}
.user-message-container{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #EAECF0;
    align-items: center;
}

.lead-message-container{
    /* background-color: red; */
    padding: 2%;
    max-height: 88vh;
    overflow-y: auto;

}

.user-message-details-container{
    display: flex;
    flex-direction: column;
}

.user-name-message{
    font-size: 14px;
    font-weight: 500;
    color: #475467;
}

.user-name-email{
    font-size: 14px;
    font-weight: 300;
    color: #175CD3;
}

.person-image-email{
    width: 40px;
    height: 40px;
    margin-right: 10px;
}

.person-image-msg-container{
    width: 48px;
    height: 48px;
    margin-right: 10px;
    border-radius: 50%;
    border-bottom: 1px solid #EAECF0;
}

.email-sent-time{
    color: #475467;
    font-size: 14px;
}

.single-person-name{
    font-size: 14px;
    font-weight: 500;
}

.single-person-date{

    font-size: 14px;
    font-weight: 400;
    color: #475467;

}


.mail-type-container{
    display: flex;
    width: 90%;
    justify-content: center;
    align-items: center;
    border: 1px solid #D0D5DD;
    border-radius: 6px;
    height: 40px;
    font-size: 16px;
    font-weight: 600;
    margin-top: 10px;
    margin-left: 5%;
    margin-bottom: 10px;
}

.mail-type-single{
    width: 50%;
    /* background-color: red; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    

}

.inbox-header{
    /* border-bottom: 2px solid #007AFF; */
    color: #007AFF;
}

.mail-type-single:first-child {
    border-right: 1px solid #D0D5DD;
}

/* .inbox-header{
    border-right: 1px solid #D0D5DD;
} */

.message {
    margin: 10px 0;
}

.message-body {
    padding: 10px;
    border-radius: 8px;
    /* display: inline-block; */
    /* max-width: 60%; */
}

.message.sent .message-body {
    background-color: #e1f5fe;
    text-align: right;
    font-size: 14px;
}

.message.received .message-body {
    background-color: #f0f0f0;
    text-align: left;
}

.reply-section {
    padding: 10px;
    border-top: 1px solid #ddd;
}

.reply-section .form-control {
    border-radius: 20px;
    border: 1px solid #ddd;
}

.reply-section .btn-primary {
    border-radius: 20px;
}



/* Side Panel Styles */
.side-panel {
    position: fixed;
    right: 0;
    top: 0;
    height: 100%;
    width: 50%;
    background-color: #fff;
    box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    z-index: 1050;
    transition: transform 0.3s ease-in-out;
    transform: translateX(0);
}

.side-panel-header {
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #ddd;
}

.single-person-container{
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.side-panel-header h5 {
    margin: 0;
    font-size: 1.25rem;
}

.close-btn {
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
}

.side-panel-body {
    /* padding: 1rem; */
    flex-grow: 1;
    overflow-y: auto;
    /* border : 5px solid #00056a0f; */
    margin: 10px;
}

.side-panel-footer {
    padding: 1rem;
    display: flex;
    justify-content: flex-end;
    /* border-top: 1px solid #ddd; */
}

.side-panel-footer button {
    margin-left: 0.5rem;
}


.no-lead-selected-message{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    font-size: 16px;
    font-weight: 500;
}

.mail-box-animation{
    width: 400px;
    height: 400px;
}

.mail-box-container{
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mail-loader-animation{
    width: 250px;
    height: 250px;
}
.main-loader-container{
    height: 88vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slected-user{
    background-color: white;
}

.user-message-subject{
    padding: 2%;
    border-bottom: 1px solid #EAECF0;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #101828;

}

.email-card {
    position: relative;
    margin-bottom: 30px; /* Adjust space for the arrow */
  }
  
  .arrow-button-container {
    position: absolute;
    bottom: -10px; /* Slightly above the bottom */
    left: 50%; /* Center horizontally */
    transform: translateX(-50%); /* Adjust for center alignment */
    background: white;
    border-radius: 50%;
    width: 30px; /* Fixed width for smaller size */
    height: 30px; /* Fixed height for smaller size */
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1); /* Soft shadow */
  }
  
  .arrow-button {
    border: none;
    background: transparent;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .arrow-button svg {
    font-size: 16px; /* Adjust the icon size */
    color: #333; /* Set the color for better visibility */
  }
  
  .sample-email {
    font-style: italic;
    color: #666;
  }
  
  
  .reply-to{
    font-weight: 500;
    color: black;
  }
  
  .to-box{
    display: flex;
    /* background-color: red; */
    border-bottom: 1px solid #e1e2ef;
  }
  .to-box-to{
    padding: 2%;
    width: 15%;
    text-align: center;
    background-color: #00056a0f;
  }
  .to-box-email{
    padding: 2%;
    width: 100%;
    /* text-align: center; */
  }
  .cc-input{
    width: 100%;
    border: none;

  }
  .to-box-cc-bcc{
    width: 10%;
    padding: 2%;
    color: #6E58F1;
    cursor: pointer;
  }
  .to-box-cc-bcc:hover {
    color: #FF5733; /* Change this to the color you want on hover */
}
.add-signature{
    background-color: #00056a0f;
    padding: 1%;
    display: flex;
    
}
.add-signature-label{
    margin-left: 5px;
}

.add-signature input[disabled] {
    cursor: not-allowed;
}