.filterBar {
    display: flex;
    align-items: center;
    /* background-color: white; */
    padding: 1rem;
    border-radius: 0.25rem;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
  
    height: 50vh;
    margin-left: 13%;
  }

  .filterBarSubmitted{
    display: flex;
    align-items: center;
    /* background-color: white; */
    padding: 1rem;
    border-radius: 0.25rem;
    /* box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05); */
  
    height: 50vh;
    margin-left: 18%;
  }
  
  .inputGroup {
    margin-right: 0.5rem;
    display: block;
  }
  
  .buttonPrimary {
    background-color: #007bff;
    border: none;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.5rem 1rem;
    border-radius: 0.25rem;
  }
  
  .buttonPrimary:hover {
    background-color: #0056b3;
  }
  

  .formLabel{
    color: #344054;
    font-weight: 400;
    display: flex;
    align-items: center;
  }

  .formUi{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 2%;
    width: 100%;

  }

  .formContainerPedning{
    
    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* margin-left: 12%; */
    border: 1px solid #EAECF0;
    background-color: #FFFFFF;
    /* background: linear-gradient(270deg, rgba(255, 255, 255, 0.8) 0.06%, rgba(242, 247, 255, 0.8) 49.83%, rgba(255, 255, 255, 0.8) 49.85%);; */
    font-size: 0.9rem;
    border-radius: 10px;
  }


  .formContainerSubmittedstyles{

    display: flex;
    /* justify-content: space-between; */
    /* align-items: center; */
    /* margin-left: 12%; */
    border: 1px solid #EAECF0;
    /* background-color: #FFFFFF; */
    background: #fff;
    font-size: 0.9rem;
    border-radius: 10px;

  }

  .formInputs{
    width: 100%;
  }

  .SubmitBtn{
    width: 90%;

    display: flex;
    justify-content: end;
    flex-direction: column;
    align-items: center;

    /* margin-top: 50%; */
  }
  .fontIcons{
    margin-right: 5px;
  }

  .inputForm{
    background-color: red;
  }

  .singleForm{
    width: 90%;
    /* background-color: red; */
    margin-bottom: 20px;

  }

  .submitBtnContainer{
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .successMessage {
    text-align: center;
    padding: 2rem;
    /* background-color: #f9f9f9; */
    border-radius: 10px;
    /* box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); */
    margin-top: 2rem;
    width: 500px;
}

.loaderContainer {
    margin-bottom: 1.5rem;
}

.spinner {
    width: 50px;
    height: 50px;
}

.successTitle {
    color: #007bff;
    font-size: 2rem;
    margin-bottom: 1rem;
}

.successText {
    font-size: 1rem;
    margin-bottom: 0.5rem;
}

.secondaryText {
    font-size: 0.8rem;
    color: #666;
}

.formContainerWrapper{
  display: flex;
  width: 30vw;

}

.magicIo{
  width: 100%;
  height: 60vh;

  /* background-color: red; */

}

.magicIoHeader{
  display: flex;
  justify-content: center;
  align-items: center;
}

.formHeader{
  height: 10vh;
  display: flex;
  align-items: center;
  /* padding:5%; */
  margin: 3% 4%;
  border-bottom: 1px solid #EAECF0;
  font-size: 1.1rem;
  font-weight: 600;
}

.findLeadsLabel{
  margin-left: 5px;
}

.formUiContainer{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-bottom: 8%;
  /* margin-left: 3%;
  margin-right: 5%; */

}

.ActIoAgentLogo{
  width: 48px;
  margin-right: 10px;
}

.Onlinelogo{
  margin-left: 10px;
}


.chatContainer {
  /* width: 100% */

  border-radius: 10px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 78%;
  /* background-color: red; */
}

.messageContainer {
  display: flex;
  margin-bottom: 20px;
}

.messageContainer.user {
  justify-content: flex-end;
}

.messageContainer.bot {
  justify-content: flex-start;
}

.sender {
  font-weight: bold;
  color: #4b4b4b;
}

.timestamp {
  font-size: 12px;
  color: #a9a9a9;
  margin-bottom: 5px;
}

.messageText {
  max-width: 70%;
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

.messageContainer.user .messageText {
  background-color: #007bff;
  color: #fff;
  border-bottom-right-radius: 0;
}

.messageContainer.bot .messageText {
  background-color: #e0e0e0;
  color: #000;
  border-bottom-left-radius: 0;
}

.inputContainer {
  display: flex;
  align-items: center;
}

.input {
  width: calc(100% - 60px);
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  font-size: 14px;
}

.sendButton {
  padding: 10px;
  border-radius: 5px;
  border: none;
  background-color: #007bff;
  color: #fff;
  margin-left: 10px;
  cursor: pointer;
}

.sendButton:disabled {
  background-color: #cccccc; /* Example disabled background color */
  color: #666666; /* Example disabled text color */
  cursor: not-allowed;
}
.chatMsgContainer{
  /* background-color: red; */
  max-height: 50vh;
  overflow-y: auto;
}

.ChatSubmitBtn{
  display: flex;
  padding: 4%;
}

.messageCard {
  display: flex;
  align-items: flex-start;
  /* background-color: #f5f5f5; */
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */
  max-width: 350px;
  margin-left: 10px;
}

.messageCardUser {
  display: flex;
  align-items: flex-end;
  /* background-color: #f5f5f5; */
  padding: 10px;
  border-radius: 10px;
  /* box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); */

  margin-left: 10px;
  justify-content: flex-end;
}

.profileImage {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.messageContent {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.messageContentUser{
  display: flex;
  flex-direction: column;
}

.messageHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
}

.senderName {
  font-weight: bold;
  color: #4b4b4b;
}

.messageTimestamp {
  font-size: 12px;
  color: #a9a9a9;
}

.messageText {
  background-color: #ffffff;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  font-size: 0.8rem;
  width: 250px;
}


/* New CSS */

.searchBarContainerMain{
  width: 100%;
  height: 88vh;
  /* background-color: green; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.searchBarContainer{

  display: flex;
  justify-content: center;
  align-items: center;
}

.ChangeView{
  margin-top: 10px;
  cursor: pointer;
  color: #3a57e8;
  text-align: center;
  font-weight: 500;
}

.ChangeViewAI{
  margin-bottom: 10px;
  cursor: pointer;
  color: #3a57e8;
  text-align: center;
}